import { registerApplication } from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';

const initializeApplicationLayout = () => {
  const resolvedRoutes = constructRoutes(document.querySelector('#single-spa-layout'), {
    loaders: {},
    props: {},
    errors: {
      error: "<p>Something went wrong. Can't load this module.</p>",
    },
  });

  const applications = constructApplications({
    routes: resolvedRoutes,
    loadApp: ({ name }) => System.import(name),
  });

  const layoutEngine = constructLayoutEngine({ routes: resolvedRoutes, applications });

  layoutEngine.activate();

  applications.forEach(registerApplication);

  return { resolvedRoutes, applications, layoutEngine };
};

export default initializeApplicationLayout;
