import { initializeGA } from '@abatech/api';

import initializeApplicationLayout from './useCases/initializeApplicationLayout';
import manageUserSession from './useCases/manageUserSession';
import startApplication from './useCases/startApplication';
import startReleaseDetector from './useCases/startReleaseDetector';

initializeApplicationLayout();

startReleaseDetector();

initializeGA();

const { idleMonitor } = manageUserSession();

startApplication(idleMonitor);
