import AdminModel from '../core/AdminModel';
import { CredentialsType, HeartbeatCredentials, HeartbeatDTO } from '../core/heartbeat';
import createTimer, { OnTimeout } from '../infrastructure/createTimer';
import fetchHeartbeat from '../infrastructure/fetchHeartbeat';
import logger from '../infrastructure/logger';
import { handleLogoutRedirection } from '../utils/navigationUtils';

const sessionTimer = createTimer();

const defaultTimetoutHandler: OnTimeout = () => {
  logger.warn('Session expired, forcing logout');
  handleLogoutRedirection('sessionExpired');
};

const getAdmin = (): AdminModel | null => {
  const admin = localStorage.getItem('admin');

  return admin ? JSON.parse(admin) : null;
};

const getHeartbeatCredentials = (admin: AdminModel): HeartbeatCredentials<CredentialsType> => {
  const oktaToken = localStorage.getItem('oktaToken');

  return {
    uid: admin.uid,
    accessToken: admin.accessToken,
    client: admin.client,
    oktaToken,
  };
};

const setupValidateHeartbeat = (onTimeout: OnTimeout) => {
  const handleHeartbeatError = (error): boolean => {
    logger.error(error);
    onTimeout();

    return false;
  };

  const startAutoLogout = (expirationTime: number): void => {
    const timeout = expirationTime - Date.now();
    sessionTimer.start(onTimeout, timeout);
  };

  const validateAutoLogout = ({ exp }: HeartbeatDTO): boolean => {
    const isExpired = Date.now() >= exp;

    if (isExpired) {
      const error = new Error('The session has expired');
      return handleHeartbeatError(error);
    }

    startAutoLogout(exp);

    return true;
  };

  const validateHeartbeat = (admin: AdminModel): Promise<boolean> =>
    fetchHeartbeat(getHeartbeatCredentials(admin)).then(validateAutoLogout).catch(handleHeartbeatError);

  return validateHeartbeat;
};

const startSessionMonitor = (onTimeout: OnTimeout = defaultTimetoutHandler): Promise<boolean> => {
  const validateHeartbeat = setupValidateHeartbeat(onTimeout);
  const admin = getAdmin();

  return admin ? validateHeartbeat(admin) : Promise.resolve(false);
};

export default startSessionMonitor;

export { sessionTimer };
