import { eventEmitter, eventNames } from '@abatech/api';

import idleMonitorConfig from '../config/idleMonitorConfig';
import createIdleMonitor, { IdleMonitor } from '../infrastructure/createIdleMonitor';
import { OnTimeout } from '../infrastructure/createTimer';

import startSessionMonitor, { sessionTimer } from './startSessionMonitor';

type UpdateSessionEventData = {
  onTimeout?: OnTimeout;
};

type ManageUserSessionReturn = {
  idleMonitor: IdleMonitor;
  channel: BroadcastChannel;
};

const broadcastActions = (idleMonitor: IdleMonitor): Record<string, () => void> => ({
  [idleMonitorConfig.eventNames.START_IDLE_MONITOR]: () => idleMonitor.start(),
  [idleMonitorConfig.eventNames.STOP_IDLE_MONITOR]: () => idleMonitor.stop(),
});

const manageUserSession = (): ManageUserSessionReturn => {
  const idleMonitor = createIdleMonitor(idleMonitorConfig.timeout);
  const channel = new BroadcastChannel(idleMonitorConfig.channelName);

  eventEmitter.on(eventNames.LOG_IN, () => {
    startSessionMonitor();
    idleMonitor.start();
  });

  eventEmitter.on(eventNames.LOG_OUT, () => {
    sessionTimer.stop();
    idleMonitor.stop();
  });

  eventEmitter.on<UpdateSessionEventData>(eventNames.UPDATE_SESSION_MONITOR, ({ onTimeout } = {}) => {
    startSessionMonitor(onTimeout);
  });

  eventEmitter.on(eventNames.ENABLE_IDLE_MONITOR, () => {
    channel.postMessage(idleMonitorConfig.eventNames.START_IDLE_MONITOR);
    idleMonitor.enable();
  });

  eventEmitter.on(eventNames.DISABLE_IDLE_MONITOR, () => {
    channel.postMessage(idleMonitorConfig.eventNames.STOP_IDLE_MONITOR);
    idleMonitor.disable();
  });

  channel.onmessage = ({ data }) => {
    broadcastActions(idleMonitor)[data]?.();
  };

  return { idleMonitor, channel };
};

export default manageUserSession;

export type { UpdateSessionEventData };
