import urlsConfig from '../config/urlsConfig';
import {
  CredentialsType,
  GetHeartbeatPort,
  HeartbeatCredentials,
  ManualCredentials,
  OktaCredentials,
} from '../core/heartbeat';

type OktaHeaders = {
  Authorization: string;
};

type ManualHeaders = {
  'Access-Token': string;
  Client: string;
  Uid: string;
};

const heartbeatUrl = `${urlsConfig.apiUrl}heartbeat`;

const isOktaCredentials = (credentials: HeartbeatCredentials<'okta' | 'manual'>): credentials is OktaCredentials =>
  'oktaToken' in credentials && !!credentials.oktaToken;

const getOktaHeaders = (credentials: OktaCredentials): OktaHeaders => ({
  Authorization: `Bearer ${credentials.oktaToken}`,
});

const getManualHeaders = (credentials: ManualCredentials): ManualHeaders => ({
  'Access-Token': credentials.accessToken,
  Client: credentials.client,
  Uid: credentials.uid,
});

const getHeaders = (credentials: HeartbeatCredentials<CredentialsType>): Record<string, string> =>
  isOktaCredentials(credentials) ? getOktaHeaders(credentials) : getManualHeaders(credentials);

const handleFetchResponse = (response: Response) => {
  if (!response.ok) {
    throw new Error(`Failed to fetch heartbeat: ${response.statusText}`);
  }

  return response.json();
};

const fetchHeartbeat: GetHeartbeatPort = (credentials) =>
  fetch(heartbeatUrl, {
    headers: getHeaders(credentials),
  }).then(handleFetchResponse);

export default fetchHeartbeat;
