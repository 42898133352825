import { ImportmapDTO } from '../core/Importmap';

import fetchImportmap from './fetchImportmap';
import logger from './logger';

type VerifyNewRelease = () => Promise<boolean>;

type AbaReleaseDetector = {
  verifyNewRelease: VerifyNewRelease;
  appendToWindow: () => void;
};

declare global {
  interface Window {
    ABA_IMPORTMAP: ImportmapDTO;
    abaReleaseDetector: AbaReleaseDetector;
  }
}

const WARNING_MESSAGE = 'An updated version of the application is ready. Reloading!';

const hasAnyNewMFEVersion = (importmap: ImportmapDTO, newImportmap: ImportmapDTO): boolean => {
  const newImportmapKeys = Object.keys(newImportmap.imports);

  return newImportmapKeys.some((key) => newImportmap.imports[key] !== importmap.imports[key]);
};

const reloadBrowser = (message: string) => {
  logger.warn(message);
  window.location.reload();
};

const verifyNewRelease: VerifyNewRelease = async () => {
  try {
    const importmap = await fetchImportmap();

    if (!hasAnyNewMFEVersion(window.ABA_IMPORTMAP, importmap)) {
      return false;
    }

    reloadBrowser(WARNING_MESSAGE);

    return true;
  } catch (error) {
    logger.error(error);
    return false;
  }
};

const releaseDetector: AbaReleaseDetector = {
  verifyNewRelease,
  appendToWindow() {
    window.abaReleaseDetector = this;
  },
};

export default releaseDetector;
