import { start } from 'single-spa';

import { IdleMonitor } from '../infrastructure/createIdleMonitor';

import startSessionMonitor from './startSessionMonitor';

const startApplication = (idleMonitor: IdleMonitor): Promise<void> =>
  startSessionMonitor().then((isAuthenticated) => {
    if (isAuthenticated) {
      idleMonitor.start();
    }

    return start();
  });

export default startApplication;
