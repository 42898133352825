type OnTimeout = () => void;

type Timer = {
  start: (onTimeout: OnTimeout, timeout: number) => void;
  stop: () => void;
};

const createTimer = (): Timer => {
  let timer: NodeJS.Timeout | null = null;

  const stop = () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  };

  const start = (onTimeout: OnTimeout, timeout: number) => {
    stop();

    timer = setTimeout(onTimeout, timeout);
  };

  return { start, stop };
};

export default createTimer;

export type { OnTimeout, Timer };
