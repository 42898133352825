import urlsConfig from '../config/urlsConfig';

const getLogoutPathWithReason = (reason: string) => `${urlsConfig.autoLogoutPath}?reason=${reason}`;

const handleLogoutRedirection = (reason: string) => {
  if (window.location.pathname !== urlsConfig.autoLogoutPath) {
    window.location.href = getLogoutPathWithReason(reason);
  }
};

const navigationUtils = {};

export default navigationUtils;

export { handleLogoutRedirection };
