import { addErrorHandler, AppError, getAppStatus, LOAD_ERROR } from 'single-spa';

import releaseDetector from '../infrastructure/releaseDetector';

const releaseDetectorErrorHandler = (error: AppError) => {
  if (getAppStatus(error.appOrParcelName) === LOAD_ERROR) {
    releaseDetector.verifyNewRelease();
  }
};

const startReleaseDetector = () => {
  addErrorHandler(releaseDetectorErrorHandler);

  releaseDetector.appendToWindow();
};

export default startReleaseDetector;
