const idleMonitorConfig = {
  timeout: Number(process.env.IDLE_TIMEOUT_MILLISECONDS),
  lastActivityStorageKey: 'IdleMonitorLastActivityTimestamp',
  disabledEventsCounterStorageKey: 'IdleMonitorDisabledEventsCounter',
  channelName: 'idleMonitorChannel',
  eventNames: {
    START_IDLE_MONITOR: 'startIdleMonitor',
    STOP_IDLE_MONITOR: 'stopIdleMonitor',
  },
};

export default idleMonitorConfig;
